import { memo } from 'react';
import {
  Toolbar,
  Box,
  Backdrop,
  ThemeProvider,
  Hidden,
  IconButton,
} from '@material-ui/core';
import Icon from 'design-system/icons/Icon';
import { WalletBalanceWidget } from '@wallet/account';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import NavbarLogo from '../../../../../../src/design-system/logos/NavbarLogo';
import { Container } from '../../../../../../src/design-system/atoms/layout';
import { Link } from '../../../../../shared';
import UserMenuButton from './UserMenuButton';
import { theme as saasTheme } from '../../../../../auth/ui/common/theme/theme';
import MobileNavbar from './mobile/MobileNavbar';
import { XAppBar } from '../XAppBar';
import NavbarItemsWrapper from './NavbarItemsWrapper';
import { useTopbarPrivate, useTopbarPrivateStyles } from './hooks';

const TopbarPrivate = memo(() => {
  const classes = useTopbarPrivateStyles({});
  const {
    anchorElMenu,
    openDrawer,
    businessName,
    handleOpenDrawer,
    handleCloseDrawer,
    handleClickMenu,
    handleCloseMenu,
    logoutClick,
    currentPath,
    navItems,
    isNavbarDropdownOpen,
    setIsNavbarDropdownOpen,
  } = useTopbarPrivate();

  return (
    <ThemeProvider theme={saasTheme}>
      <XAppBar>
        <Container>
          <Toolbar disableGutters>
            <Box mr={5}>
              <Link href="/" data-qa="topbar-home-navigation-link">
                <NavbarLogo />
              </Link>
            </Box>
            <Hidden smDown>
              <NavbarItemsWrapper
                navItems={navItems}
                isNavbarDropdownOpen={isNavbarDropdownOpen}
                setIsNavbarDropdownOpen={setIsNavbarDropdownOpen}
                currentPath={currentPath}
              />
            </Hidden>
            <Box flexGrow={1} />

            <WalletBalanceWidget />

            <Hidden smDown>
              <UserMenuButton
                anchorElMenu={anchorElMenu}
                businessName={businessName}
                handleClickMenu={handleClickMenu}
                handleCloseMenu={handleCloseMenu}
                logoutClick={logoutClick}
              />
            </Hidden>

            <Hidden mdUp>
              <IconButton
                className={classes.menuIcon}
                aria-label="Open Navigation"
                edge="end"
                onClick={handleOpenDrawer}
              >
                <Icon
                  fontSize="medium"
                  color="action"
                  iconDefinition={faBars}
                />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </XAppBar>

      <MobileNavbar
        menuItems={navItems}
        open={openDrawer}
        onClose={handleCloseDrawer}
      />

      <Backdrop
        className={classes.backdrop}
        open={isNavbarDropdownOpen || Boolean(anchorElMenu)}
      />
    </ThemeProvider>
  );
});

export default TopbarPrivate;
