import { Box, makeStyles, useTheme } from '@material-ui/core';
import Link from 'next/link';
import { useState } from 'react';
import { useMobile } from '@features/home/infra/context/useMobile';
import { Typography } from '../../../../../../src/design-system/atoms';
import { ContentItemButton } from '../domain/ContentItemButton';

const useStyles = makeStyles(() => ({
  navButtonContentItemRoot: {
    display: 'flex',
    height: '100%',
    cursor: 'pointer',
    textDecoration: 'none',
  },
}));

function VerticalBar({ isActive }: { isActive: boolean }) {
  const theme = useTheme();
  const isMobile = useMobile();

  return (
    <Box
      marginRight={2}
      width={6}
      borderRadius={8}
      height="100%"
      bgcolor={
        isActive || isMobile
          ? theme.palette.primary.main
          : theme.palette.grey[200]
      }
    />
  );
}

interface IProps extends ContentItemButton {
  currentPath: string;
}

function NavbarSubItem({
  label,
  description,
  path,
  dataQa,
  currentPath,
}: IProps) {
  const classes = useStyles();
  const isActive = currentPath.includes(path);
  const [isHover, setIsHover] = useState(false);

  return (
    <Link href={path} passHref data-qa={dataQa}>
      <Box
        className={classes.navButtonContentItemRoot}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        component="a"
        minWidth={350}
      >
        <VerticalBar isActive={isActive || isHover} />

        <div>
          <Typography
            variant="body1"
            color={isActive || isHover ? 'primary' : 'textPrimary'}
            gutterBottom
            component="div"
          >
            <Box fontWeight="fontWeightMedium">{label}</Box>
          </Typography>
          <Typography variant="body3" color="textSecondary">
            {description}
          </Typography>
        </div>
      </Box>
    </Link>
  );
}

export default NavbarSubItem;
