import clsx from 'clsx';
import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  Paper,
  Popper,
  Slide,
  Typography,
} from '@material-ui/core';
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NextLinkComposed } from '../../../../../shared';
import { Navbar } from '../domain/Navbar';
import NavbarSubItem from './NavbarSubItem';
import { isNavbarButton } from './utils/isNavbarButton';
import { useTopbarPrivateStyles } from './hooks';

const defaultPaperSizes = { width: 10, height: 10 };

interface IProps {
  navItems: Navbar;
  isNavbarDropdownOpen: boolean;
  setIsNavbarDropdownOpen: Dispatch<SetStateAction<boolean>>;
  currentPath: string;
}

function NavbarItemsWrapper({
  navItems,
  isNavbarDropdownOpen,
  setIsNavbarDropdownOpen,
  currentPath,
}: IProps) {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const paperRef = useRef<HTMLElement | null>(null);

  const [paperSizes, setPaperSizes] = useState(defaultPaperSizes);
  const [wasCalculated, setWasCalculated] = useState(false);

  const [navigationState, setNavigationState] = useState({
    anchorEl: null as HTMLElement | null,
    value: 0,
    prevValue: 0,
  });

  const classes = useTopbarPrivateStyles({ wasCalculated, paperSizes });

  const handleDropdownOpen = (
    index: number,
    event: MouseEvent<HTMLButtonElement>
  ) => {
    setNavigationState({
      anchorEl: event.currentTarget,
      value: index,
      prevValue: navigationState.value,
    });
    setIsNavbarDropdownOpen(true);
    setWasCalculated(false);
  };

  const handleDropdownClose = () => {
    setIsNavbarDropdownOpen(false);
    setNavigationState(prevState => ({
      ...prevState,
      anchorEl: null,
    }));
  };

  useEffect(() => {
    setWasCalculated(false);
    if (paperRef.current && navigationState.value) {
      const { width, height } =
        paperRef.current?.getBoundingClientRect() || defaultPaperSizes;
      setPaperSizes({ width, height });
      setWasCalculated(true);
    }
  }, [paperRef, navigationState.value]);

  return (
    <Box onMouseLeave={handleDropdownClose}>
      {navItems.map((navItem, index) =>
        isNavbarButton(navItem) ? (
          <Button
            key={navItem.navButtonLabel}
            className={classes.navItemButton}
            component={NextLinkComposed}
            to={navItem.path}
            onMouseEnter={handleDropdownClose}
            size="small"
            color="primary"
            variant="text"
            data-qa={navItem.dataQa}
          >
            {navItem.navButtonLabel}
          </Button>
        ) : (
          <Button
            key={navItem.navButtonLabel}
            className={clsx(classes.navItemButton, {
              [classes.navItemButtonActive]:
                index === navigationState.value && isNavbarDropdownOpen,
            })}
            onMouseEnter={event => handleDropdownOpen(index, event)}
            size="small"
            color="primary"
            variant="text"
          >
            {navItem.navButtonLabel}
          </Button>
        )
      )}

      <Popper
        open={isNavbarDropdownOpen}
        anchorEl={navigationState.anchorEl}
        className={classes.popper}
        placement="bottom"
        id="menu-list-grow"
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window',
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={400}>
            <div>
              <span className={classes.arrow} ref={setArrowRef} />

              <Paper className={classes.paper} ref={paperRef}>
                {navItems.map((navItem, index) => {
                  const enter = index === navigationState.value;

                  const inDirection =
                    navigationState.prevValue < navigationState.value
                      ? 'left'
                      : 'right';

                  const outDirection =
                    navigationState.prevValue < navigationState.value
                      ? 'right'
                      : 'left';

                  return (
                    !isNavbarButton(navItem) && (
                      <Fade in={enter} timeout={400}>
                        <div>
                          <Slide
                            in={enter}
                            direction={enter ? inDirection : outDirection}
                            timeout={400}
                          >
                            <div
                              className={
                                enter
                                  ? classes.positionRelative
                                  : classes.positionAbsolute
                              }
                            >
                              <Box padding={4} paddingBottom={3}>
                                <Typography component="div" variant="body2">
                                  <Box fontWeight="fontWeightMedium">
                                    {navItem.content.headerTitle}
                                  </Box>
                                </Typography>
                              </Box>

                              <Divider />

                              <Box maxWidth={680} padding={4} paddingTop={3}>
                                <Grid container spacing={4}>
                                  {navItem.content.items.map(item => (
                                    <Grid
                                      item
                                      xs={6}
                                      key={item.path}
                                      onClick={handleDropdownClose}
                                    >
                                      <NavbarSubItem
                                        label={item.label}
                                        description={item.description}
                                        path={item.path}
                                        dataQa={item.dataQa}
                                        currentPath={currentPath}
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </Box>
                            </div>
                          </Slide>
                        </div>
                      </Fade>
                    )
                  );
                })}
              </Paper>
            </div>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}

export default NavbarItemsWrapper;
