import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
  Slide,
  makeStyles,
} from '@material-ui/core';
import Icon from 'design-system/icons/Icon';
import React, { Dispatch, useState } from 'react';
import { NextLinkComposed, Spacer } from '@features/shared';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { Navbar } from '../../domain/Navbar';
import MobileNavbarLayout from './MobileNavbarLayout';
import { NavbarButtonGroup } from '../../domain/NavbarButtonGroup';
import { ContentItemButton } from '../../domain/ContentItemButton';
import { NavbarItem } from '../../domain/NavbarItem';
import { isNavbarButton } from '../utils/isNavbarButton';
import NavbarSubItem from '../NavbarSubItem';

const useStyles = makeStyles(theme => ({
  navbarLink: {
    textDecoration: 'none',
  },
  navSubItemListItem: {
    marginBottom: theme.spacing(3),
  },
}));

interface IProps {
  menuItems: Navbar;
  open: boolean;
  onClose: () => void;
}

function MobileNavbar({ menuItems, open, onClose }: IProps) {
  const [group, setGroup]: [NavbarButtonGroup, Dispatch<NavbarButtonGroup>] =
    useState(null);
  const classes = useStyles();

  const currentPath = window.location.pathname;

  return (
    <MobileNavbarLayout
      group={group}
      setGroup={setGroup}
      open={open}
      onClose={onClose}
    >
      {group === null && (
        <Slide direction="right" in={group === null} mountOnEnter unmountOnExit>
          <List>
            {menuItems.map((navItem: NavbarItem) => {
              const { navButtonLabel } = navItem;
              if (isNavbarButton(navItem)) {
                const { path, dataQa } = navItem;
                return (
                  <>
                    <ListItem
                      key={navButtonLabel}
                      button
                      className={classes.navbarLink}
                      onClick={onClose}
                      component={NextLinkComposed}
                      to={path}
                      data-qa={dataQa}
                    >
                      <ListItemText disableTypography>
                        <Typography
                          component="div"
                          variant="body2"
                          color="textSecondary"
                        >
                          <Box fontWeight="bold">{navButtonLabel}</Box>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Spacer spacing={2} />
                  </>
                );
              }
              return (
                <>
                  <ListItem
                    key={navButtonLabel}
                    button
                    onClick={() => setGroup(navItem)}
                  >
                    <ListItemText disableTypography>
                      <Typography
                        component="div"
                        variant="body2"
                        color="textSecondary"
                      >
                        <Box fontWeight="bold">{navButtonLabel}</Box>
                      </Typography>
                    </ListItemText>
                    <ListItemIcon>
                      <Box justifyContent="end" display="flex" width="100%">
                        <Icon
                          fontSize="medium"
                          color="action"
                          iconDefinition={faChevronRight}
                        />
                      </Box>
                    </ListItemIcon>
                  </ListItem>
                  <Spacer spacing={2} />
                </>
              );
            })}
          </List>
        </Slide>
      )}
      {group && (
        <Slide
          direction={group === null ? 'right' : 'left'}
          in={group !== null}
          mountOnEnter
          unmountOnExit
        >
          <List>
            <Box padding={2} paddingTop={1}>
              <Typography variant="body1" color="primary">
                {group.navButtonLabel}
              </Typography>
              <Spacer spacing={1} />
              <Typography variant="body2">
                {group.content.headerTitle}
              </Typography>
              <Spacer spacing={4} />
              {group.content.items.map((itemButton: ContentItemButton) => {
                const { label, description, dataQa, path } = itemButton;
                return (
                  <ListItem
                    key={label}
                    className={classes.navSubItemListItem}
                    button
                    onClick={onClose}
                    disableGutters
                  >
                    <Box height={48}>
                      <NavbarSubItem
                        label={label}
                        description={description}
                        path={path}
                        currentPath={currentPath}
                        dataQa={dataQa}
                      />
                    </Box>
                  </ListItem>
                );
              })}
            </Box>
          </List>
        </Slide>
      )}
    </MobileNavbarLayout>
  );
}

export default MobileNavbar;
