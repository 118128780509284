import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Slide,
} from '@material-ui/core';
import Icon from 'design-system/icons/Icon';
import { faXmark, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import React, { Dispatch } from 'react';
import { Typography } from 'design-system/atoms';
import { Link, Spacer } from '../../../../../../shared';
import { logoutUser } from '../../../../../../../src/store/actions/authenticationActions';
import { features } from '../../../../../../../src/config/features';
import { NavbarButtonGroup } from '../../domain/NavbarButtonGroup';

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.down('sm')]: {
      width: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

interface IProps {
  group: NavbarButtonGroup;
  setGroup: Dispatch<NavbarButtonGroup>;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

function MobileNavbarLayout({
  group,
  setGroup,
  open,
  onClose,
  children,
}: IProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const countryId = process.env.NEXT_PUBLIC_COUNTRY_ID;
  const { profileSidebar } = features[countryId];
  const shouldShowProfileMenuItems = group === null;
  const handleClose = () => {
    setGroup(null);
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      variant="temporary"
      onClose={handleClose}
      classes={{ paper: classes.drawer }}
    >
      <Slide direction="right" in={open} mountOnEnter unmountOnExit>
        <Box
          display="flex"
          justifyContent="space-between"
          height={64}
          flexDirection="row-reverse"
          paddingX={2}
          paddingTop={1}
          paddingBottom={1}
        >
          <IconButton
            edge="end"
            color="inherit"
            size="medium"
            onClick={handleClose}
          >
            <Icon fontSize="medium" color="action" iconDefinition={faXmark} />
          </IconButton>
          {group !== null && (
            <IconButton
              color="inherit"
              size="medium"
              edge="start"
              onClick={() => setGroup(null)}
            >
              <Icon
                fontSize="medium"
                color="action"
                iconDefinition={faArrowLeft}
              />
            </IconButton>
          )}
        </Box>
      </Slide>
      {children}
      {shouldShowProfileMenuItems && (
        <>
          <Divider />
          <Slide
            direction={open ? 'right' : 'left'}
            in={open}
            mountOnEnter
            unmountOnExit
          >
            <List
              subheader={
                <Typography component="div" variant="body1" color="primary">
                  <Box
                    paddingLeft={2}
                    fontWeight="bold"
                    paddingTop={4}
                    paddingBottom={3}
                  >
                    Perfil de usuario
                  </Box>
                </Typography>
              }
            >
              {profileSidebar.map(({ label, path, dataQa }) => {
                return (
                  <>
                    <ListItem
                      key={label}
                      button
                      onClick={onClose}
                      data-qa={dataQa}
                      {...{
                        component: Link,
                        href: path,
                        noLinkStyle: true,
                      }}
                    >
                      <ListItemText disableTypography>
                        <Typography
                          component="div"
                          variant="body2"
                          color="textSecondary"
                        >
                          <Box fontWeight="bold">{label}</Box>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Spacer spacing={2} />
                  </>
                );
              })}

              <ListItem
                key="logout"
                button
                onClick={() => {
                  onClose();
                  dispatch(logoutUser());
                }}
                data-qa="logout"
                {...{
                  href: '/logout',
                  noLinkStyle: true,
                }}
              >
                <ListItemText disableTypography>
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                  >
                    <Box fontWeight="bold">Cerrar sesión</Box>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Slide>
        </>
      )}
    </Drawer>
  );
}
export default MobileNavbarLayout;
