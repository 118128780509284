import { useState } from 'react';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  Paper,
  Popper,
} from '@material-ui/core';
import {
  faChevronDown,
  faChevronUp,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import Icon from '../../../../../../src/design-system/icons/Icon';
import { Typography } from '../../../../../../src/design-system/atoms';
import { Link, Spacer } from '../../../../../shared';
import { useTopbarPrivateStyles } from './hooks';

interface IProps {
  handleClickMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseMenu: () => void;
  anchorElMenu: HTMLElement;
  businessName: string;
  logoutClick: () => void;
}

function UserMenuButton({
  handleClickMenu,
  handleCloseMenu,
  anchorElMenu,
  businessName,
  logoutClick,
}: IProps) {
  const classes = useTopbarPrivateStyles({});
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const locales = {
    PROFILE: 'Mi perfil',
    LOG_OUT: 'Cerrar sesión',
  };

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        onClick={handleClickMenu}
        color="default"
        size="small"
      >
        <Avatar className={classes.avatar}>
          <Icon fontSize="small" color="action" iconDefinition={faUser} />
        </Avatar>

        <Icon
          fontSize="small"
          color="action"
          iconDefinition={anchorElMenu ? faChevronUp : faChevronDown}
        />
      </IconButton>

      <Popper
        open={Boolean(anchorElMenu)}
        anchorEl={anchorElMenu}
        className={classes.popper}
        placement="top-end"
        id="menu-list-grow"
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window',
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Box minWidth={266}>
            <Paper className={classes.paper}>
              <Box padding={3}>
                <Typography component="div" variant="body2">
                  <Box fontWeight="fontWeightMedium">{businessName}</Box>
                </Typography>
              </Box>

              <Divider />

              <Box padding={3}>
                <Link href="/user/profile" passHref underline="none">
                  <Typography
                    className={classes.menuItemText}
                    component="a"
                    variant="body3"
                    color="textSecondary"
                  >
                    <Box fontWeight="fontWeightMedium">{locales.PROFILE}</Box>
                  </Typography>
                </Link>

                <Spacer spacing={3} />

                <Box onClick={logoutClick}>
                  <Typography
                    className={classes.menuItemText}
                    variant="body3"
                    color="textSecondary"
                  >
                    <Box fontWeight="fontWeightMedium">{locales.LOG_OUT}</Box>
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default UserMenuButton;
